// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NielsShoeMeulman-module--CarrouselWrapper2--WCXSa";
export var DescriptionWrapper = "NielsShoeMeulman-module--DescriptionWrapper--kUYIU";
export var DownloaderWrapper = "NielsShoeMeulman-module--DownloaderWrapper--yKJOq";
export var EventWrapper = "NielsShoeMeulman-module--EventWrapper--PlUbn";
export var ImageWrapper = "NielsShoeMeulman-module--ImageWrapper--4jR8j";
export var ImagesWrapper = "NielsShoeMeulman-module--ImagesWrapper--YqhMt";
export var MobileTile = "NielsShoeMeulman-module--MobileTile--Jdl9x";
export var PdpWrapper = "NielsShoeMeulman-module--PdpWrapper--9Sgv4";
export var PhotosWrapper = "NielsShoeMeulman-module--PhotosWrapper--q+sx4";
export var TitleWrapper = "NielsShoeMeulman-module--TitleWrapper--92-0w";
export var Wrapper = "NielsShoeMeulman-module--Wrapper--lznXs";